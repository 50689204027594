import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './routes/App';
import reportWebVitals from './reportWebVitals';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import ErrorPage from './routes/ErrorPage';
import LiveMap from './routes/LiveMap';
import Home from './routes/Home';
import FlightInformation from './routes/FlightInformation';
import ConnectingFlights from './routes/ConnectingFlights';
import InsiderTips from './routes/InsiderTips';
import Entertainment from './routes/Entertainment';
import ChatAssistant from './routes/ChatAssistant';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <ErrorPage />,
    children: [
      { index: true, element: <Home /> },
      { path: "/flight-information", element: <FlightInformation /> },
      { path: "/live-map", element: <LiveMap /> },
      { path: "/connecting-flights", element: <ConnectingFlights /> },
      { path: "/insider-tips", element: <InsiderTips /> },
      { path: "/entertainment", element: <Entertainment /> },
      { path: "/chat-assistant", element: <ChatAssistant /> }
    ]
  },
]);

root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
