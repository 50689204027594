import Chat from "../components/Chat";

export default function ChatAssistant() {
  return (
    <>
      <h1>ChatAssistant</h1>
      <p>Coming soon!</p>
      <Chat />
    </>
  );
}
