export default function Home() {
  return (
    <>
      <h1>Home</h1>
      <div>
        Soon™
      </div>
    </>
  );
}
