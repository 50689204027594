import { useRouteError } from "react-router-dom";
import Header from "../components/Header";

export default function ErrorPage() {
  const error: any = useRouteError();
  return (
    <>
      <Header />
      <div id="error-page">
        <h1>Oops!</h1>
        <p>Something went wrong.</p>
        <p>
          <i>{error.statusText || error.message}</i>
        </p>
      </div>
    </>
  );
}
