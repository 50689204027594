import React from "react";
import './Chat.css';
import axios from "axios";

interface Message {
  role: "assistant" | "user" | "error";
  content: string;
}

const LOCAL_URL = "http://localhost:35655/api/chat";
const REMOTE_URL = "/api/chat";

const DEFAULT_MESSAGE_BEGIN: Message[] = [
  { role: "assistant", content: "Welcome to our automated customer service portal!" },
  { role: "assistant", content: "How can I help you?" },
];

export default function Chat() {
  const [messages, setMessages] = React.useState<Message[]>(DEFAULT_MESSAGE_BEGIN);
  const [userMessage, setUserMessage] = React.useState<string>("");
  const [assistantTyping, setAssistantTyping] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (assistantTyping) {
      const url = process.env.NODE_ENV === "development" ? LOCAL_URL : REMOTE_URL;
      axios
        .post(url, { history: messages.filter(m => m.role !== "error") })
        .then(response => {
          const message: Message = { role: "assistant", content: response.data.response };
          setMessages([...messages, message]);
          setAssistantTyping(false);
        })
        .catch(error => {
          console.error("Error sending message to assistant:", error);
          const message: Message = { role: "error", content: "Error sending message to assistant." };
          setMessages([...messages, message]);
          setAssistantTyping(false);
        });
    }
  }, [assistantTyping]); // eslint-disable-line react-hooks/exhaustive-deps

  async function sendMessage(content: string) {
    // We can not send the message while the assistant is typing
    if (assistantTyping)
      return;

    // If the message is empty, we do not send it
    if (content === "")
      return;

    // Add the user message to the chat
    const message: Message = { role: "user", content };
    setMessages([...messages, message]);
    setUserMessage("");

    // Update the assistant typing state last, so that the useEffect hook can send the message
    setAssistantTyping(true);
  }

  function onKeyDown(event: React.KeyboardEvent<HTMLTextAreaElement>) {
    if (event.key === "Enter" && event.shiftKey === false) {
      event.preventDefault();
      sendMessage(userMessage);
    }
  }

  function onChange(event: React.ChangeEvent<HTMLTextAreaElement>) {
    setUserMessage(event.target.value);

    // Size the textarea to fit the content
    const minHeight = 40;
    event.target.style.height = 'auto'; // Reset height to auto
    event.target.style.height = Math.max(minHeight, event.target.scrollHeight) + "px";
  }

  function onClick() {
    sendMessage(userMessage);
  }

  return <>
    <section>
      <div className="chat">
        {messages.map((m, i) => <div key={i} className={m.role}>{m.content}</div>)}
        {assistantTyping && <div className="assistant"><div className="loading"></div></div>}
      </div>
      <div className="editor">
        <textarea
          onKeyDown={onKeyDown}
          value={userMessage}
          onChange={onChange} />
        <button
          onClick={onClick}
          disabled={assistantTyping}
        >Send</button>
      </div>
    </section>
  </>
}
