import { MdMenu } from "react-icons/md";
import "./Header.css";
import { Link } from "react-router-dom";

export default function Header() {
  return (
    <header>
      <div><Link to='/'>Bodenhansa</Link></div>
      <input type="checkbox" id="header-menu"/>
      <label htmlFor="header-menu"><MdMenu /></label>
      <nav>
        <ul>
          <li><Link to='/flight-information'>Flight Information</Link></li>
          <li><Link to='/live-map'>Live Map</Link></li>
          <li><Link to='/connecting-flights'>Connecting Flights</Link></li>
          <li><Link to='/insider-tips'>Insider Tips</Link></li>
          <li><Link to='/entertainment'>Entertainment</Link></li>
          <li><Link to='/chat-assistant'>Chat Assistant</Link></li>
        </ul>
      </nav>
    </header>
  )
}
